* {
    font-weight: 500;
    box-sizing: border-box;
    color: #000;
    font-smooth: always;
    font-family: "Saira";
}

p {
    font-family: 'Saira', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */


    /* COLOR - CLARO */

    /*color: #FDF4F4;*/

}

body, html {
    background: url("BACK.svg") no-repeat;
    background-size: cover;
    font-family: "Saira", sans-serif;
    font-weight: 900;
    margin: 0;
    min-height: 100vh;
    width: 100%;

}

.title-font-box {
    font-family: 'Saira';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 104%;
    /* or 33px */
    top: 0;

    /* COLOR - CLARO */

    color: #FDF4F4;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.claimButton {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 40px;
    gap: 10px;
    border-radius: 30px;

}


.coins {
    /* COINS SMALL */

    background: url("back_coins_full.png") no-repeat;
    position: absolute;
    background-size: cover;
    width: 550px;
    height: 1400px;
    left: 50%;
    top: 10%;

}

.coins_initial {
    background: url("coins_back_initial.png") no-repeat;
    position: absolute;
    background-size: cover;
    width: 650px;
    height: 100%;
    left: 50%;
    top: 10%;
}

@media only screen and (max-width: 600px) {
    .coins {
        left: 0;
    }
    .coins_initial {
        display: none;
    }
}

.container {
    /* Desktop - 4 */

    position: relative;
    width: 1440px;
    height: 841px;
}

.title-home {
    /* JOIN THE KEPHI CLUB */

    font-family: "Saira", sans-serif;
    font-style: normal;
    font-weight: 900 !important;
    font-size: 64px;
    line-height: 90%;
    /* or 58px */


    /* GRADIENT - GALLERY */

    background: linear-gradient(90deg, #C24075 0%, #FF914C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;


}

.description-home {
    font-family: "Saira", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    /* or 24px */

    /* COLOR - CLARO */

    color: #FDF4F4;

    padding-top: 1%;

}

.box-home {
    position: absolute;
    width: 440px;
    height: 224px;
    left: 270px;
    top: 60%;

    background: rgba(30, 31, 37, 0.7);
    border-radius: 10px;
    padding: 2.5%;
}

.button-home {
    /* BOTON */
    border-radius: 100rem;

    font-family: "Saira", sans-serif;
    font-size: 1rem;

    color: #FFFFFF;
    border: solid 3px whitesmoke;
    align-items: center;
    width: 100%;
    background-origin: border-box;
    background-clip: content-box, border-box;
    padding: 5%;
    vertical-align: center;
    text-align: center;
    bottom: 10%;
    overflow: hidden;
background: transparent;


}


.loader-svg {
    height: 200px;
    width: 200px;
}

.loader {
    position: fixed;
    height: 100%;
    width: 100vw;
    top: 0;
    right: 0;
    background: url("BACK.svg") no-repeat;
    background-size: cover;
    z-index: 9999;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.clubhouse {
    min-height: 100%;
}

.clbtn {
    background: transparent;
    height: 52px;
    outline: 0;
    border-radius: 30px;
    border-color: rgba(194, 64, 117, 1) rgba(194, 64, 117, 1) rgba(255, 145, 76, 1) rgba(255, 145, 76, 1);
    color: #fff;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 18px;
    width: 100%;
    position: relative;
    transition: .2s;
    gap: 10px;
}

.btn.Waiting {
    background: #ffcc91;
}

.clbtn:hover {
    background: linear-gradient(90deg, #C24075 0%, #FF914C 100%);
    cursor: pointer;
    border: none;
}

.ch-bg {
    background: url("coins.svg") no-repeat;
    background-size: cover;
    left: 60%;
    min-height: 480px;
    filter: drop-shadow(0 0 0 rgba(0, 0, 0, .25));
    border-radius: 20px
}

.ch-overlay {
    height: 100%;
    width: 100%;
    max-width: 450px;
    backdrop-filter: blur(10px);
    padding: 25px
}

.ch-info {
    background: rgba(30, 31, 37, 0.7);
    border-radius: 10px;
    box-shadow: 0 0 1px rgba(40, 41, 61, .04), 0 2px 4px rgba(96, 97, 112, .16);
    padding: 20px;
}

@keyframes glowing {
    0% {
        background-position: 0 0
    }

    50% {
        background-position: 400% 0
    }

    100% {
        background-position: 0 0
    }
}

body {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto
}

p {
    max-width: 100%;
    overflow-wrap: break-word
}

.icon-btn {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden
}

.d-none {
    display: none !important
}

.box {
    position: relative;
    margin: 8px;
    transition: .2s
}

.tophalf {
    display: flex;
    justify-content: space-between
}

.gametxtinput {
    /* COLOR - CLARO 25% */

    border: 1px solid rgba(253, 244, 244, 0.25);
    background-color:  rgba(30, 31, 37, 0.7);
    border-radius: 10px;
    height: 48px;
    color: whitesmoke;
}

.gameinput.b {
    padding-bottom: 30px;
    border-bottom: 1px solid #f0f0f0
}

.inpboxg {
    position: relative
}

.gametxtinp {
    background: #efefef;
    height: 48px;
    border: 0;
    outline: 0;
    border-radius: 10px
}

.betamount {
    width: 100%
}

.stats {
    width: 50%
}

.stakebtn {
    width: 70%;
    height: 48px;
    background: #EE8B18;
    border-radius: 12px;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    outline: 0;
    border: 0;
    transition: .2s;
    cursor: pointer;
    display: block
}

.stakebtn.gr {
    background: #c4c4c4
}

.stakebtn.bl:hover, .swapbtn:hover {
    background: linear-gradient(90deg, #C24075 0%, #FF914C 100%);
    cursor: pointer;
    border: none;
}

.stakebtn.gr:hover {
    background: #a4a4a4
}

.btn:disabled:hover {
    background: #ffcc91;
    cursor: default
}

.stakebtn.ico:disabled {
    background: #ffcc91;
}

.txwait {
    height: 30px;
    width: 30px;
    opacity: 0
}

.txwait.Staking, .txwait.Unstaking {
    opacity: 1
}

.stakemore {
    display: flex;
    justify-content: space-between
}

.infoInp {
    position: relative;
    width: 32%
}

.infoInp img {
    width: 16px;
    height: 16px
}

.modal {
    height: 100%;
    position: fixed;
    right: -300px;
    background-color: #fbfbfb;
    width: 290px;
    z-index: 90000000;
    box-shadow: 0 0 4px 4px #0000001A;
    top: 0;
    transition: .2s ease
}

.modal.expanded {
    right: 0;
}

.modal-overlay.expanded {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 903;
    top: 0;
    left: 0;
    background: #00000044;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    display: block !important;
}

.modal-overlay {
    transition: .2s;
    color: #fff;
    position: fixed
}

.topbar {
    height: 56px;
    position: relative;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #f0f0f0
}

.cross {
    margin-left: 10px !important
}

.stakeInp {
    min-width: 100%
}

.percBtns {
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between
}

.percBtn {
    border-radius: 12px;
    height: 100%;
    border: 0;
    outline: 0;
    background: #eee;
    padding-left: 12px;
    padding-right: 12px
}

.stakebtn.big {
    height: 48px;
    width: 100%;
    position: relative
}

.btn.Staking, .btn.Unstaking {
    animation-name: active;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    color: #fff
}

.stakebtn.ico {
    background: #EE8B18
}

.bg-gr {
    background-color: #a3d37d;
    color: #3d6120;
    padding: 15px;
    font-size: 16px
}

.swapbox {
    background: rgba(30, 31, 37, 0.7);
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .25);
    padding: 20px;
    max-width: 400px;
    width: 100%;
    margin: auto;
    z-index: 100;
    position: relative
}

.swapWrapper {
    padding-top: 10%;
    position: relative;
    height: 400px;
}

.disabled:before {
    content: "Binance Smart Chain";
}

.disabled:hover {
    background: #ee5e1b !important;

}

.disabled:hover:after {
    content: " - Coming Soon";
}

hr {
    border: 1px solid #f0f0f0
}

.swapbtn {
    background: transparent;
    height: 52px;
    outline: 0;
    border-radius: 30px;
    border-color: rgba(194, 64, 117, 1) rgba(194, 64, 117, 1) rgba(255, 145, 76, 1) rgba(255, 145, 76, 1);
    color: #fff;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 18px;
    width: 100%;
    position: relative;
    transition: .2s;
    gap: 10px;
}

.ico {
    min-height: 100%;
    background-color: #f7f7f8
}

.ico * {
    transition: .2s
}

.icoWarapper {
    background-color: transparent;
    min-height: 100%;
    padding-top: 40px;
    overflow: hidden;
    max-width: 1400px;
    display: block;
    margin: auto;
}

.ico-info {
    display: flex;
    overflow: auto
}

.ico-info-box {
    /*background: #fff;*/
    box-shadow: 0 0 1px rgba(40, 41, 61, .04), 0 2px 4px rgba(96, 97, 112, .16);
    /*border-radius: 16px;*/
    width: 30%;
    max-width: 450px;
    min-width: 360px;
    padding: 18px;
    padding-right: 66px;
    position: relative;
    margin-right: 35px;
    background: rgba(30, 31, 37, 0.7);
    border-radius: 10px;
}

.ico-info-box p {
    margin: 0
}

.ico-top-stats {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap
}

@media (max-width: 550px) {
    .ico-info-box {
        width: 100% !important;
        max-width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-bottom: 15px;
        min-width: 0;
        padding-right: 20px
    }

    .ico-info-box.tp {
        min-height: 129px
    }

    .ico-info {
        flex-wrap: wrap;
        padding-left: 40px;
        padding-right: 40px
    }

    .swapWrapper {
        padding-top: 10%;
        position: relative;
        height: 400px;
    }
}

@media (max-width: 480px) {
    .ico-info-box .bb {
        font-size: 20px
    }

    .ico-info-box.tp {
        min-height: 115px
    }
}

.ch-bot {
    padding-left: 40px;
    padding-right: 40px
}

@media (max-width: 550px) {
    .ch-bot, .ico-info, .icot {
        padding-left: 20px;
        padding-right: 20px
    }
}

@media (max-width: 350px) {
    .ico-info-box svg {
        display: none
    }
}

@media (max-width: 850px) {
    .hamb-menu {
        margin-left: 4px !important
    }

    .box {
        margin: 0
    }
}

@media (max-width: 484px) {
    .modal {
        height: 100%;
        position: fixed;
        right: calc(-100% - 5px);
        background-color: #fbfbfb;
        width: 100%;
        z-index: 90000000;
        box-shadow: 0 0 4px 4px #0000001A;
        top: 0
    }
}

.hamb-menu {
    transition: .2s;
    margin-left: -40px
}

.hamburger-svg, .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.hamburger-line {
    fill: none;
    stroke: #666;
    stroke-width: 6;
    transition: stroke-dasharray .6s cubic-bezier(.4, 0, .2, 1), stroke-dashoffset .6s cubic-bezier(.4, 0, .2, 1)
}

.hamburger-line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6
}

.hamburger-line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6
}

.hamburger-line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6
}

.opened .hamburger-line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6
}

.opened .hamburger-line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6
}

.opened .hamburger-line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6
}

@keyframes active {
    0% {
        background: #EE8B18
    }

    50% {
        background: #a25600
    }

    100% {
        background: #EE8B18
    }
}